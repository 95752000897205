<template>
  <b-card v-if="orderStatuses" :title="`${$t('relation_statuses')}`">
    <b-form inline>
      <div class="form-group">
        <label for="type">Якщо {{ $t('payment_method') }}</label>
        <select class="form-control ml-2 mr-2" v-model="form.payment">
          <option v-for="payment in payments" :key="payment.id" :value="payment.id">{{ payment.name }}</option>
        </select>
      </div>
      +
      <div class="form-group">
        <select class="form-control ml-2 mr-2" v-model="form.pStatus">
          <option v-for="payment in paymentStatuses" :key="payment.id" :value="payment.id">{{ payment.name }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="type">=</label>
        <select class="form-control ml-2 mr-2" v-model="form.oStatus">
          <option v-for="payment in orderStatuses" :key="payment.id" :value="payment.id">{{ payment.name }}</option>
        </select>
      </div>
      <div class="form-group">
        <b-button variant="primary" @click="addCondition">Додати умову</b-button>
      </div>
    </b-form>
    <b-row v-if="!loading">
      <b-col>
        <ul class="list-group mt-2">
          <li v-for="(el, index) in elements" :key="index" class="list-group-item">
            {{ index + 1 }}. Якщо оплата <strong>{{ el.payment.name }}</strong> і статус оплати <strong>{{ el.pStatus.name }}</strong> то статус замовлення = <strong>{{ el.oStatus.name }}</strong>
            <b-button size="sm" variant="outline-danger" @click="removeEl(index, el.id)"><fa icon="trash" /></b-button>
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
  data: () => {
    return {
      orderStatuses: null,
      paymentStatuses: null,
      payments: null,
      form: {
        payment: null,
        pStatus: null,
        oStatus: null
      },
      elements: [],
      loading: true
    }
  },
  mounted () {
    this.fetchPayments()
    this.fetchStatuses()
    setTimeout(() => {
      this.fetchRelationStatuses()
    }, 200)
  },
  methods: {
    async addCondition () {
      if (this.elements.length === 0) {
        const p = this.payments.find(e => e.id === this.form.payment)
        const ps = this.paymentStatuses.find(e => e.id === this.form.pStatus)
        const o = this.orderStatuses.find(e => e.id === this.form.oStatus)
        this.elements.push({
          payment: { id: p.id, name: p.name },
          pStatus: { id: ps.id, name: ps.name },
          oStatus: { id: o.id, name: o.name }
        })
      } else {
        const check = this.elements.find(el => {
          if ((this.form.payment === el.payment.id) && (this.form.pStatus === el.pStatus.id) && (this.form.oStatus === el.oStatus.id)) {
            return true
          } else {
            return false
          }
        })
        if (typeof check === 'undefined') {
          const p = this.payments.find(e => e.id === this.form.payment)
          const ps = this.paymentStatuses.find(e => e.id === this.form.pStatus)
          const o = this.orderStatuses.find(e => e.id === this.form.oStatus)
          this.elements.push({
            payment: { id: p.id, name: p.name },
            pStatus: { id: ps.id, name: ps.name },
            oStatus: { id: o.id, name: o.name }
          })
        } else {
          Swal.fire({
            title: 'Помилка',
            text: 'Ця комбінація вже існує',
            icon: 'info',
            confirmButtonText: 'Ok'
          })
        }
      }
      this.updateStatuses()
      setTimeout(() => {
        this.fetchRelationStatuses()
      }, 200)
    },
    async removeEl (idx, id) {
      this.elements.splice(idx, 1)
      // this.updateStatuses()
      await this.removeItem(id)
      setTimeout(() => {
        this.fetchRelationStatuses()
      }, 200)
    },
    removeItem (id) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1;
      axios.delete(apiUrl + 'relationStatuses/' + id)
    },
    async updateStatuses () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1;
      const arr = []
      this.elements.forEach(el => {
        arr.push({
          payment: el.payment.id,
          p_status: el.pStatus.id,
          o_status: el.oStatus.id
        })
      })
      await axios.post(apiUrl + 'relationStatuses', arr)
    },
    async fetchRelationStatuses () {
      this.elements = []
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.get(apiUrl + 'relationStatuses')
      if (data) {
        console.log(this.paymentStatuses)
        data.forEach(el => {
          if (this.orderStatuses) {
            const p = this.payments.find(e => e.id === el.payment)
            const ps = this.paymentStatuses.find(e => e.id === el.p_status)
            const o = this.orderStatuses.find(e => e.id === el.o_status)
            this.elements.push({
              id: el.id,
              payment: { id: p.id, name: p.name },
              pStatus: { id: ps.id, name: ps.name },
              oStatus: { id: o.id, name: o.name }
            })
          }
        })
        this.loading = false
      }
    },
    async fetchPayments() {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.get(apiUrl + 'payments')
      this.payments = data.data
    },
    async fetchStatuses () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.get(apiUrl + 'statuses')
      this.orderStatuses = data.data.filter(s => s.type === 'order')
      this.paymentStatuses = data.data.filter(s => s.type === 'payment')
    },
  }
}
</script>
